import React from "react";
import { Link } from "gatsby";
import { IoMdArrowRoundForward } from "react-icons/io";
import Author from "./Author";
import ArticleBadge from "./ArticleBadge";
import CategoryTag from "./CategoryTag";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Essay = (props) => {
  const { excerpt } = props;
  const { slug, title, categories, date, timeToRead } = props.frontmatter;
  const image = getImage(props.frontmatter.image)
  const categoryTags = categories.map((c, index) => (
    <CategoryTag tag={c} key={index} />
  ));
  const essayUrl = `/${slug}`;

  return (
    <article className="grid md:grid-cols-12 gap-6 md:my-4">
      <Link to={essayUrl} className="md:col-span-4">
        <GatsbyImage
          className="rounded-xl w-full h-full"
          alt="image"
          image={image}
        />
      </Link>

      <div className="md:col-span-8">
        <ArticleBadge />
        <Link to={essayUrl}>
          <h3 className="font-bold text-gray-900 hover:underline text-2xl mb-4">
            {title}
          </h3>
          <p className="text-gray-500 md:max-w-lg">{excerpt}</p>
        </Link>
        {categoryTags}
        <Link
          to={essayUrl}
          className="uppercase text-indigo-600 flex hover:text-indigo-800 items-center mt-3"
        >
          <span className="font-semibold">Read full story</span>
          <IoMdArrowRoundForward className="text-2xl" />
        </Link>
        <Author timeToRead={timeToRead} date={date} />
      </div>
    </article>
  );
};
export default Essay;
