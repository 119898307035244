import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import EssayList from "../components/EssayList";
import { SEO } from "../components/SEO";

const Essays = (props) => {
  return (
    <>
      <Layout>
        <h1 className="font-extrabold text-3xl mt-8 mb-2 md:mb-0">
          All Essays
        </h1>
        <EssayList essays={props.data.allMdx.nodes} />
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt
        id
        frontmatter {
          author
          slug
          date(formatString: "MMM Do, YYYY")
          categories
          timeToRead
          title
          image {
            childImageSharp {
                gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export const Head = () => <SEO
  title="Essays"
  pathname="/essays"
  description="List of all essays"
/>

export default Essays;
