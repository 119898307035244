import React from "react";
import Essay from "./Essay";
import Sidebar from "./Sidebar";

const EssayList = (props) => {
  const essays = props.essays.map((essay, index) => (
    <React.Fragment key={essay.id}>
      <Essay {...essay} />
      {index < props.essays.length - 1 && (
        <hr className="md:hidden my-8" key={`hr-${essay.id}`} />
      )}
    </React.Fragment>
  ));
  return (
    <div className="grid grid-cols-1 lg:grid-cols-5">
      <div className="lg:col-span-4">{essays}</div>
      <div className="lg:col-span-1 lg:mt-0 mt-8 md:mt-4">
        <Sidebar />
      </div>
    </div>
  );
};

export default EssayList;
