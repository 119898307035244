import React from "react";

const SidebarTitle = (props) => {
  return (
    /*
      need to have two relative divs so that the horizontal lines are not
      placed behind the background
     */
    <div className="relative">
      <div className="relative text-center mb-4" style={{ zIndex: 100 }}>
        <h4 className="uppercase font-semibold text-lg">
          <span className="bg-gray-50 px-3" >{props.title}</span>
        </h4>
      </div>
      <div className="absolute top-1/2 left-0 w-full h-0.5 bg-blue-800"></div>
    </div>
  );
};

export default SidebarTitle;
