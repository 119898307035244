import React from "react";

const ArticleBadge = () => {
  return (
    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
      Article
    </span>
  );
};

export default ArticleBadge;
