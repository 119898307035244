import React from "react";
import { Link,} from "gatsby";
import { StaticImage } from "gatsby-plugin-image";


const Author = (props) => {
  return (
    <div className="mt-3 flex items-center">
      <div className="flex-shrink-0">
        <Link to="/about">
          <StaticImage
            className="h-10 w-10 rounded-full"
            src="../images/author.jpg"
            alt=""
          />
        </Link>
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-900">
          <Link to="/about" className="hover:text-gray-900 hover:underline">
            Jan Goebel
          </Link>
        </p>
        <div className="flex space-x-1 text-sm text-gray-500">
          <time>{props.date}</time>
          <span aria-hidden="true">&middot;</span>
          <span>{props.timeToRead} min read</span>
        </div>
      </div>
    </div>
  );
};

export default Author;
