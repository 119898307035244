import React from "react";
import { Link } from "gatsby";

const CategoryTag = (props) => {
  return (
    <Link
      to={`/category/${props.tag}`}
      className="font-semibold text-blue-500 hover:text-blue-800 mr-2"
    >
      #{props.tag}
    </Link>
  );
};

export default CategoryTag;
