import React from "react";
import SidebarTitle from "./SidebarTitle";
import { graphql, useStaticQuery } from "gatsby";
import CategoryTag from "./CategoryTag";
import { FaYoutube } from "react-icons/fa";

const query = graphql`
  {
    allMdx {
      categories: distinct(field: frontmatter___categories)
    }
  }
`;

const Sidebar = (props) => {
  const twitterFeedHeight = props.longTwitter ? "1500" : "345";

  const queryReslt = useStaticQuery(query);
  const categoryTags = queryReslt.allMdx.categories.map((c, index) => (
    <CategoryTag tag={c} key={index} />
  ));
  return (
    <aside className="grid md:grid-cols-2 lg:grid-cols-1 md:gap-8 lg:mt-6">
      <div>
        <SidebarTitle title="Staying in touch" />
        <h5 className="text-gray-800 font-medium mt-4">Follow me on Twitter</h5>
        <a
          href="https://twitter.com/_jgoebel?ref_src=twsrc%5Etfw"
          className="twitter-follow-button"
          data-size="large"
          data-show-count="false"
        >
          Follow @_jgoebel
        </a>
        <div className="mb-4">
          <h5 className="text-gray-800 font-medium mt-4">
            Let&apos;s connect on YouTube
          </h5>
          <a
            href="https://www.youtube.com/channel/UCFOmyIxUSbCqTLWoa4IODxw"
            style={{
              maxWidth: "195px",
              fontSize: "13px",
            }}
            className="flex mb-2 items-center px-2 py-1 rounded text-white bg-red-600 hover:bg-red-700"
          >
            <FaYoutube className="text-xl" />
            <span className="ml-1">Follow me on YouTube!</span>
          </a>
        </div>
      </div>
      <div>
        <SidebarTitle title="Categories" />
        <div className="flex flex-col">{categoryTags}</div>
      </div>

      <div className="hidden lg:block">
        <SidebarTitle title="News" />

        <a
           className="twitter-timeline"
           data-width="307"
           data-height={twitterFeedHeight}
           href="https://twitter.com/_jgoebel?ref_src=twsrc%5Etfw"
        >
            Tweets by _jgoebel
        </a>
        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      </div>
    </aside>
  );
};

export default Sidebar;
